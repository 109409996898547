<template>
    <b-modal id="novo-produto" ref="modalNovoProduto" centered :title="titleModal" class="titleModal" size="md" v-model="showModal" @hide="fecharModal">
        <b-container>
            <b-row class="data-field" style="margin-left:0;">
                <!--CAIXAS DE TEXTO =====================================-->
                <b-col class="data-field p-2">
                    <label>SKU</label>
                    <input type="text" v-model="novoProduto.SKU"/>
                </b-col>
                <b-col class="data-field p-2">
                    <label>Referência</label>
                    <input type="text" v-model="novoProduto.Referencia"/>
                </b-col>
            </b-row>


            <b-row class="data-field" style="margin-left:0;">
                <b-col class="data-field p-2" >
                    <label>Produto</label>
                    <input type="text" v-model="novoProduto.Nome"/>
                </b-col>
            </b-row>


            <b-row class="data-field" style="margin-left:0;">
                <b-col class="data-field p-2">
                    <label>Quantidade</label>
                    <input type="number" v-model="novoProduto.Quantidade" @input="novoProduto.Quantidade = parseInt(novoProduto.Quantidade)" @keydown="isNumber" min="0"/>
                </b-col>

                <b-col class="data-field p-2">
                    <label>Valor</label>
                    <input type="number" v-model="novoProduto.Valor" @input="novoProduto.Valor = parseFloat(novoProduto.Valor)" @keydown="isNumber" min="0"/>
                </b-col>
            </b-row>


            
            <!--<b-row class="produtoDigitalBox">
                <b-col>
                    <input type="checkbox" id="produtoDigitalBox" name="produtoDigital" value="produtoDigital">
                    <label for="produtoDigital" class="labelCheckbox">
                        <span>
                            Produto digital
                            <label class="subLabel">(Sem necessidade de estoque)</label>
                        </span>
                    </label>
                </b-col>
            </b-row>-->
        </b-container>

        <!--BOTOES FOOTER MODAL =============================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>

            <b-button id="enviarBtn" variant="success" @click="adicionarProduto" class="rounded-0" :disabled="!camposPreenchidos || dataSaving">
                <span v-if="dataSaving">
                    <b-spinner small class="mr-1"></b-spinner>
                    Salvando...
                </span>
                <span v-else>Salvar</span>
            </b-button>

        </template>

    </b-modal>

</template>

<!--=====================================================================================-->

<script>
    import ProdutosServices from '@/assets/js/services/ProdutosServices'
    export default {
        props:['campos'],

        data() {
            return {
                titleModal:null ,

                novoProduto: {
                    id: null,
                    SKU: null,
                    Referencia: null,
                    Quantidade: null,
                    Nome: null,
                    Valor: null,
                    Status: 1,
                    Tags: '',
                },
                showModal: false,
                dataSaving: false,
            }
        },

        computed: {
            camposPreenchidos() { 
                return(
                    this.novoProduto.Nome &&
                    this.novoProduto.Quantidade &&
                    this.novoProduto.Valor
                );
            }
        },

        methods: {

            iniciarModal(produto) {
                this.novoProduto.id = produto?.Id;
                this.novoProduto.SKU = produto?.SKU;
                this.novoProduto.Referencia = produto?.Referencia;
                this.novoProduto.Quantidade = produto?.Quantidade;
                this.novoProduto.Valor = produto?.Valor;
                this.novoProduto.Nome = produto?.Nome;
                if (this.novoProduto.id = produto?.Id) {
                    this.titleModal = 'Editar Produto'
                } else {
                    this.titleModal = 'Novo Produto'
                }

                this.$bvModal.show("novo-produto");
            },

            adicionarProduto() {
                if (this.novoProduto.id) {
                    this.$emit('editar-produto', this.novoProduto);
                   

                }else{
                    this.$emit('novo-produto', this.novoProduto);
                }
                this.fecharModal();
                this.showModal = false;
            },

            limparCampos() {
                this.novoProduto.id = null;
                this.novoProduto.SKU = null;
                this.novoProduto.Referencia = null;
                this.novoProduto.Quantidade = null;
                this.novoProduto.Valor = null;
                this.novoProduto.Nome = null;
                this.dataSaving = false;
               
            },

            fecharModal() {
                this.limparCampos()
            },

            //Função para validar os campos de numero, e bloquear caracteres indesejados.
            isNumber(event) {
                const isBackspace = event.key === 'Backspace';
                const isSelection = event.key === 'a' && (event.ctrlKey || event.metaKey); 

                if (!isBackspace && !isSelection) {
                    const invalidCharsRegExp = /[-+eE]/;
                    if (invalidCharsRegExp.test(event.key)) {
                        event.preventDefault();
                    }

                }
            }
        }

    }

</script>

<!--=====================================================================================-->

<style scoped>

    #novo-produto input[type=text] {
        background-color: #F5F2F2;
        border: 1px #737373 solid;
    }    
    
    #novo-produto input[type=number] {
        background-color: #F5F2F2;
        border: 1px #737373 solid;
    }

    .data-field {
        color: #737373;
    }

    .labelCheckbox {
        color: #737373;
        font-weight: bold;
        margin-left: 5px;
        font-size: 13px;
        vertical-align:text-top;
    }

    .subLabel{
        font-size:10px;
        font-weight:normal;
    }

    .produtoDigitalBox input[type=checkbox] {
        border-radius: 50px;
        font-size: 12px;
    }

    #enviarBtn:disabled{
        opacity: 0.5;
    }
</style>