<template>
	<div class="bar-breadcrumb">
		<div class="row w-100">
			<div class="col">
				<span class="titulo">{{titulo}}</span>
			</div>
			<div class="col" id="nav-breadcrumb">

				<i class="fa fa-home mr-1" style="font-size:12px">
				</i>
				<a class="lista-breadcrumb" 
				   href="/"> Ínicio</a>

				<span v-for="item in items"
					  class="lista-breadcrumb">
					<i :class="`${item.classe} mr-1`"></i>
					<router-link class="lista-breadcrumb" :to="item.link">
						{{item.texto}}
					</router-link>
				</span>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Breadcrumb',
		props: {
			titulo: String,
			items: Array,
		}
	}
</script>

<style scoped>
    .bar-breadcrumb {
		overflow:auto;
		display:row;
        color: white;
        background-color: rgb(37, 156, 211) !important;
        padding: 15px 10px;
        width: 100%;
		min-width:650px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
		border-radius: 0 !important;
    }

	.titulo {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
	}

	#nav-breadcrumb {
		text-transform: capitalize;
		text-align: right;
	}

    .lista-breadcrumb {
        font-size: 12px;
        color: white;
        text-decoration: none;
		font-weight:bold;
        font-family: Arial;
    }

		.lista-breadcrumb + .lista-breadcrumb:before {
			content: "/";
			display: inline-block;
			margin: 0 9px;
		}

    .lista-breadcrumb :hover{
		color: var(--cor-primaria-cliente);
    }

</style>