<template>
    <div>
        <Breadcrumb titulo="Produtos" :items="itemsBreadcrumb" />
        <div id="container_produtos" class="container_produtos">
            <div class="row">
                <div class="col-sm d-flex flex-row mr-3">

                    <input v-model="query.Query" type="text" ref="search-input" class="campos" placeholder="Busca por produtos ou códigos" @keyup.enter="buscaProdutos()" />
                    <button class="input-pesquisa-icone" @click="buscaProdutos()">
                        <b-icon icon="search"></b-icon>
                    </button>
                </div>

                <div class="ml-3">
                    <button @click="exportarProdutos()" :disabled="exportando" class="btn-ajustes btn_mod_categorias_prod mr-3">
                        <span v-if="exportando">Exportando <i class="fas fa-circle-notch fa-spin"></i></span>
                        <span v-else>Exportar dados</span>

                    </button>                    
                    


                    <!--<botao-padrao @click="importarProdutoOpnModal()" texto="Importar dados" class="btn-ajustes btn_mod_categorias_prod mr-3" />-->



                    <botao-padrao @click="novoProdutoOpnModal()" texto="Novo produto" class="btn-ajustes btn_mod_categorias_prod mr-3" />
                </div>
            </div>
            <b-table :items="listaProdutos"
                     :fields="fields"
                     ref="selectableTable"
                     responsive="sm"
                     striped hover
                     id="tableProdutos"
                     class="tabela-padrao-btable app-scroll-custom mh-100">

                <template #cell(Valor)="row">
                    <span class="app-ck-html-container" v-html="row.item.Valor.toLocaleString('pt-BR', objectConvertionMoeda)"></span> </template>

                <template #cell(botoes)="row">
                    <div class="w-100" style="display:flex">
                        <botao-tab-edit @click="modalEditProduto(row.item)"/>
                        <botao-tab-remove @click="deletaProduto(row.item.Id)" />
                    </div>
            </template>

            </b-table>
            <div class="mt-3" id="componentePaginacao">
                <!--Botão voltar pag. ----------------------->
                <button id="retrocederPag" @click="retrocederPage" :disabled="disableRetrocederPage">
                    <i class="	fas fa-angle-left"></i>
                </button>

                <!--Informações da pagina----------------------->
                <span class="infoPaginacao">{{query.Offset+1}} / {{pageCount == 0 ? 1 : pageCount}}</span>

                <!--Botão avançar pag. ----------------------->
                <button id="avancarPag" @click="avancarPage" :disabled="disableAvancarPage">
                    <i class="	fas fa-angle-right"></i>
                </button>
            </div>
        </div>

        <modalImportarProduto ref="importar-produto"/>

        <modalNovoProduto ref="novo-Produto" @novo-produto="adicionarNovoProduto"
                          @editar-produto="editarProduto" :campos="campinhos"/>
    </div>
</template>
<script>
    import { nextTick } from 'vue'
    import modalNovoProduto from '@/components/NewProdutosPage/modalNovoProduto.vue'
    import modalImportarProduto from '@/components/NewProdutosPage/importarProdutoModal.vue'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import ProdutosServices from '@/assets/js/services/ProdutosServices'
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'

    import axios from 'axios'

    export default {
        name: 'ProdutosPage',



        components: {

            Breadcrumb,
            modalImportarProduto,
            modalNovoProduto,
            BotaoPadrao,
            BotaoTabEdit,
            BotaoTabRemove
        },

        data() {
            return {

                itemsBreadcrumb: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },

                    {
                        id: '3',
                        classe: 'fas fa-box',
                        texto: 'Produtos',
                        link: '/produtos-produtos'
                    }
                ],

                exportando: false,


                fields: [
                    { key: 'SKU', label: 'SKU' },
                    { key: 'Nome', label: 'Nome' },
                    { key: 'Referencia', label: 'Referência' },
                    
                    { key: 'Valor', label: 'Preço'},
                    { key: 'Quantidade', label: 'Quantidade'},
                    { key: 'botoes', label: '', class: 'position-relative'},
                ],
                campinhos: [
                    { key: 'SKU'},
                    { key: 'Nome'},
                    { key: 'Referencia'},
                    { key: 'Valor'},
                    { key: 'Quantidade'},
                    { key: 'Tags'},
                ],
                item: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-cogs',
                        texto: 'Produtos',
                        link: '/produtos'
                    },
                    {
                        id: '3',
                        classe: 'fas fa-box-open',
                        texto: 'Produtos',
                        link: '/produtos-produtos'
                    }
                ],

                listaProdutos: [
                    {SKU: '', Referencia: '', Nome: '', Valor: 0, Quantidade: 0 },
                ],

                itemProduto: null,

                filter: '',
                query:{
                    Query: '',
                    Limit: 10,
                    Offset: 0
                },
                pageCount: null,
                totalRows: null,
                search: null,
                selecionado: 1,


                objectConvertionMoeda: { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }
            }
        },

        computed: {
            rows() {
                return this.listaProdutos.length
            },
            disableRetrocederPage(){
                if (this.query.Offset <= 0){
                    return true
                }
            },

            disableAvancarPage(){
                if (this.query.Offset >= this.pageCount -1){
                    return true
                }

            }
        },
        created(){
            this.carregaProdutos()
        },
        methods: {
            carregaCategorias: async function () {
                $("#caixa_carregamento").show()
                this.listaCategorias = await ProdutosServices.carregaCategoriasProdutos();
                $("#caixa_carregamento").hide()
            },

            carregaProdutos: async function () {
                $("#caixa_carregamento").show()
                await ProdutosServices.carregaProdutos(this.query).then(res => {
                    this.listaProdutos = res.data.Produtos;
                    this.totalRows = res.data.TotalItems;
                    
                    let page_count = Math.ceil(this.totalRows / this.query.Limit);

                    this.pageCount = page_count;
                });
                $("#caixa_carregamento").hide()
            },

            buscaProdutos: async function () {
                $("#caixa_carregamento").show()
                await ProdutosServices.buscaProdutos(this.query).then(res => {

                    this.listaProdutos = res.data.Result;

                    this.totalRows = res.data.Result.length;
                    
                    let page_count = Math.ceil(this.totalRows / this.query.Limit);

                    this.pageCount = page_count;
                });
                $("#caixa_carregamento").hide()
            },

            carregaProdutosRetorno: function (retornoCarregamento) {
                this.listaProdutos = retornoCarregamento;
            },

            async exportarProdutos() {
   
                this.exportando = true;

                axios.post(`api/produto/ExportarProdutos`, this.query, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then(res => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([res.data]))
                    link.download = `produto_listaProd_${Date.now()}.xlsx`
                    link.click();
                    link.remove();
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.exportando = false;
                });
            },

            novoProdutoOpnModal() {
                this.$refs["novo-Produto"].iniciarModal();
            },

            modalEditProduto(produto) {
                this.$refs["novo-Produto"].iniciarModal(produto);
            },

            importarProdutoOpnModal() {
                this.$refs["importar-produto"].iniciarModal();
            },

            async deletaProduto(id) {
                try {
                    const confirmado = await this.$bvModal.msgBoxConfirm("Deseja excluir o produto?", {
                        title: 'Atenção',
                        okTitle: 'Confirmar',
                        cancelTitle: 'Cancelar',
                        okVariant: 'success',
                        cancelVariant: 'white',
                        centered: true
                    });

                    if (confirmado) {
                        $("#caixa_carregamento").show();
                        await ProdutosServices.deletaProduto(id);
                        await this.carregaProdutos();
                        $("#caixa_carregamento").hide();
                    }
                } catch (error) {
                    $("#caixa_carregamento").hide();
                    console.log('Erro ao deletar produto:', error);
                }
            },

            async adicionarNovoProduto(novoProduto) {
                $("#caixa_carregamento").show();
                await ProdutosServices.salvarProduto(novoProduto); // Espera a conclusão da função salvarProduto()
                await this.carregaProdutos(); 
                $("#caixa_carregamento").hide();// Espera a conclusão da função carregaProdutos()
            },

            async editarProduto(produto) {
                $("#caixa_carregamento").show();
                await ProdutosServices.editaProduto(produto);
                await this.carregaProdutos();
                $("#caixa_carregamento").hide();
            },

            avancarPage(){
                this.query.Offset+=1
                this.carregaProdutos()
            },            

            retrocederPage(){
                this.query.Offset-=1
                this.carregaProdutos()         
            },
            importarProdutos(arquivo) {
                $("#caixa_carregamento").show()
                this.$bvToast.toast('A importação pode demorar um pouco', {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: 'info',
                    appendToast: false,
                    autoHideDelay: 9000,
                    noCloseButton: true
                });
                try {
                    ProdutosServices.importarProdutos(arquivo).then((r) => {
                        $("#caixa_carregamento").hide()
                        this.$bvToast.toast('Importação realizada com sucesso', {
                            title: `Aviso`,
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            variant: 'success',
                            appendToast: false,
                            autoHideDelay: 3000,
                            noCloseButton: true
                        });
                        this.carregaProdutos();

                    });     
                } catch (erro) {
                    console.log('erro importarProdutos', erro)
                    this.mostrarErro('Ocorreu um erro ao importar a planilha de produtos')
                }
               
            }
        }
    }
</script>

<style scoped>
    #container_produtos {
        padding: 10px;
        background-color: #f3f3f3;
        margin: 12px 8px 0px;
        min-height: 40vw;

    }

    .btn-ajustes {
        color:white;
        border:none;
        font-size: 13px;
        width: 10vw;
        height: 4vh;
        background-color: var(--cinza-2);
    }

    .btn-ajustes:disabled{
        opacity: 0.5;
    } 

    .btn_mod_categorias_prod {
        background-color: var(--cor-primaria-cliente);
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        padding: 10px;
        float: right;
        text-align: center;
    }
    .btn_mod_categorias_prod_primary {
        display: table;
        padding: 10px;
        cursor: pointer;
        padding: 0;
        width: 7%;
        text-align: center;
        margin: 0;
        padding: 0 20px 0 20px;
        color: white;
    }

    .input-pesquisa-icone {
        height: 4vh;
        width: 3vw;
        border: none;
        cursor: pointer;
        border-radius: 0;
        background-color: var(--cor-primaria-cliente);
        font-size: .9rem;
        color: white;
    }

    .input-pesquisa-icone:hover {
        opacity: 0.6;
        border: 1px var(--cor-primaria-cliente) solid;
    }

    .cor_fundo_branco{
        background-color: white
    }

    .campos {
        margin-right: 15px;
        background-color: white; 
        border: none ;
        height: 4vh; 
        width: 80vh;
        align-self: center; 
    }

    .btn_mod_categorias_prod:hover {
        background-color: #1b99a3;
    }
    .btn_mod_categorias_prod_primary:hover {
        background-color: #ccc;
    }

    .tabela_listagem_campanha {
        font-size: 13px;
        font-family: verdana;
        margin-top: 12px;
        margin-bottom: 25px;
    }
    .item_lista_campanha {
        padding: 5px;
        display: block;
    }

    .acoes_campanhas {
        background-color: #e9e9ef;
        padding: 3px;
        margin-right: 7px;
        cursor: pointer;
        min-width: 43px;
        display: inline-block;
        text-align: center;
    }

    .tabela-padrao-btable {
        background-color: #fff;
        height: calc(100vh - 250px);
        overflow-y: auto;
        margin-bottom: 0;
        font-size: 12px;
        padding: 0 7px 7px 7px;
        margin: 8px 0
    }

    .linha_par {
        background-color: #f5f5f5;
    }
    #componentePaginacao {

        display: flex;
        justify-content: center;
        text-align: start;
        font-size: 20px;
        color: #707070;
    }

    #retrocederPag {
        color: #707070;
        padding: 0;
        margin-right: 10px;
        border: none;
        background-color: transparent;
        }    
        #retrocederPag:disabled {
        opacity:0.5;
    }

    #avancarPag {
        margin-top: 2px;
        color: #707070;
        padding: 0;
        margin-left: 10px;
        border: none;
        background-color: transparent;
        }

        #avancarPag:disabled {
        opacity: 0.5;
    }

    .infoPaginacao {
        margin-top: 2px;
        font-size: 17px;
        font-weight: bold;
    }
    
</style>