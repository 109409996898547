<template>
    <div class="d-inline-block">
        <i  title="Remover" class="fas fa-trash-alt mx-2" @click="$emit('click')"></i>
    </div>
</template>

<script>
    export default { name: 'BotaoTabRemove' }
</script>

<style scoped>
    i {
        cursor: pointer;
    }

        i.fas {
            font-size: .8rem;
        }
</style>