import axios from 'axios'
const URL_API = '/api'
/// CATEGORIAS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const tamanhoMaximoCategoriasProdutos = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/buscarTamanhoMaximoCategoriasProdutos`)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro buscar tamanho maximo de Categorias Produtos', e)
        throw (e)
    }
}
const carregaCategoriasProdutos = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/carregaCategoriasProdutos`)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar categoria de produtos', e)
        throw (e)
    }
}
const salvarCategoria = async (dados) => {
    try {
        const { data } = await axios.post(`${URL_API}/produtos/SalvarCategoriaProduto`, dados)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro salvar categoria de produtos', e)
        throw (e)
    }
}

const deletaCategoria = async (id) => {
    try {
        const { data } = await axios.post(`${URL_API}/produtos/deletaCategoria/` + id)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro deletar campanhas', e)
        throw (e)
    }
}



const filtrarCategorias = async (filterNome, filterCodigo) => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/filtrarCategorias?Nome=${filterNome}`)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro filtrar', e)
        throw(e)
    }
}
const paginandoCategoriasProdutos = async (paginaSelecionada, filterNome) => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/paginacaoCategoriasProdutos?pagina=${paginaSelecionada}&Nome=${filterNome}`)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro paginação', e)
        throw(e)
    }
}
/// PRODUTOS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const tamanhoMaximoProdutos = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/buscarTamanhoMaximoProdutos`)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro buscar tamanho maximo de Produtos', e)
        throw (e)
    }
}
const carregaSituacaoOrcamento = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/tabulacao/carregaSituacaoOrcamento`)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar categoria de produtos', e)
        throw (e)
    }
}
const carregaProdutos = async (query) => {
    try {
        let dados = await axios.post(`${URL_API}/produto/busca-produtos-pag`,query)
        return dados
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar produtos', e)
        throw (e)
    }
}
const exportarProduto = async (detalhes) => {
    try {
        axios.post(`${URL_API}/produto/ExportarProdutos`, detalhes, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.data]))
            link.download = `produto_listaProd_${Date.now()}.xlsx`
            link.click();
            link.remove();
        }).catch(err => {
            console.log(err);
        });

    } catch (e) {
        console.log('erro ao exportar produtos', e);
        throw (e);
    }
}
const buscaProdutos = async (query) => {
    try {
        let dados = await axios.post(`${URL_API}/produto/busca-produtos-search`,query)
        return dados
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar produtos', e)
        throw (e)
    }
}
const carregaProdutosPorCatg = async (id) => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/carregaProdutosPorCatg/`+id)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar produtos por categoria', e)
        throw (e)
    }
}
const importaCsv = async (formData) => {
    try {
        const { data } = await axios.post(`${URL_API}/produtos/ImportaCsv`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro deletar campanhas', e)
        throw (e)
    }
}
const salvarProduto = async (dados) => {
    try {
        const { data } = await axios.post(`${URL_API}/produto/salva-produto`, dados)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro salvar categoria de produtos', e)
        throw (e)
    }
}
const editaProduto = async (dados) => {
    try {
        const { data } = await axios.put(`${URL_API}/produto/edita-produto`, dados)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro salvar categoria de produtos', e)
        throw (e)
    }
}
const deletaProduto = async (id) => {
    try {
        const { data } = await axios.delete(`${URL_API}/produto/` + id)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro deletar campanhas', e)
        throw (e)
    }
}
const filtrarProdutos = async (filterNome, filterPreco) => {
    try {
        const dados = "Nome=" + filterNome + "&Preco=" + filterPreco

        const { data } = await axios.get(`${URL_API}/produtos/filtrarProdutos?` + dados)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro filtrar', e)
        throw (e)
    }
}
const paginandoProdutos = async (paginaSelecionada, filterNome, filterPreco) => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/paginacaoProdutos?pagina=${paginaSelecionada}&Nome=${filterNome}&Preco=${filterPreco}`)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro paginação', e)
        throw (e)
    }
}
/*OUTROS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
const carregaImagensOrcamento = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/discoVirtual/imagensOrcamento`)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar categoria de produtos', e)
        throw (e)
    }
}
const carregaEdicaoOrcamento = async (atendimentoId) => {
    try {
        const { data } = await axios.get(`${URL_API}/produtos/carregaEdicaoOrcamento/`+atendimentoId)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar edicao orcamento', e)
        throw (e)
    }
}
const salvarOrcamento = async (dados) => {
    try {
        const { data } = await axios.post(`${URL_API}/produtos/salvarOrcamento`, dados)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro salvar categoria de produtos', e)
        throw (e)
    }
}
const salvarNumeroAtividade = async (id,cod) => {
    try {
        const { data } = await axios.post(`${URL_API}/produtos/salvarNumeroAtividade/`+ id+"/" + cod)
        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro salvar categoria de produtos', e)
        throw (e)
    }
}
const importarProdutos = async (dataProdutos) => {
    try {
        const response = await axios.post(`${URL_API}/Produto/importarProdutos`, dataProdutos);
        return response ? response.data : null;
    } catch (e) {
        LogServices.logErro(e, 'importarProdutos');
        throw (e);
    }
}
export default {
    salvarCategoria,
    carregaCategoriasProdutos,
    deletaCategoria,
    salvarProduto,
    carregaProdutos,
    deletaProduto,
    carregaProdutosPorCatg,
    editaProduto,
    carregaSituacaoOrcamento,
    carregaImagensOrcamento,
    salvarOrcamento,
    carregaEdicaoOrcamento,
    salvarNumeroAtividade,
    importaCsv,
    exportarProduto,
    buscaProdutos,
    filtrarProdutos,
    filtrarCategorias,
    paginandoProdutos,
    paginandoCategoriasProdutos,
    tamanhoMaximoCategoriasProdutos,
    tamanhoMaximoProdutos,
    importarProdutos
}