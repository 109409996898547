<template>
    <b-button :disabled="disabled" class="botao-padrao" v-on:click="$emit('click')">
        <slot></slot>{{texto}}
    </b-button>
</template>

<script>
export default {
    name: 'BotaoPadrao',
    props: {
        texto: String,
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.botao-padrao {
    cursor: pointer;
    background-color: var(--cor-primaria-cliente);
    border: none;
    color: #fff;
    height: 35px;
    font-size: 13px;   
    border-radius: inherit;   
}
    .botao-padrao:hover {
        opacity: 0.7;
    }
    .botao-padrao:disabled {
        color: var(--cinza-5);
        background-color: var(--cinza-4);
    }
</style>
