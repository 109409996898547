<template>
    <b-modal id="importar-produto" ref="modalImportarProduto" centered title="Importar Produto" class="titleModal" size="lg" @hide="">

        <b-container>

            <b-row>
                <b-col class="data-field p-2">
                    <label>Selecionar arquivo Excel</label>
                    <div>
                        <input type="file" ref="fileInput" @change="handleFileUpload" accept=".xls,.xlsx" >
                    </div>
                </b-col>       
            </b-row>

        </b-container>


        <!--BOTOES FOOTER MODAL =============================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>

            <b-button id="enviarBtn" variant="success" @click="" class="rounded-0" :disabled="dataSaving">
                <span v-if="dataSaving">
                    <b-spinner small class="mr-1"></b-spinner>
                    Salvando...
                </span>
                <span v-else>Confirmar</span>
            </b-button>

        </template>

    </b-modal>

</template>

<!--=====================================================================================-->

<script>
    export default {

        data() {
            return {
                dataSaving: false,
                selectedFile: null
            }
        },

        methods: {


            iniciarModal() {
                this.$bvModal.show("importar-produto");
            },

            handleFileUpload(event) {
                this.selectedFile = event.target.files[0];
                // L�gica para processar o arquivo selecionado
            }

        }

    }

</script>

<!--=====================================================================================-->

<style scoped>

    #importar-produto input[type=text] {
        opacity: 1;
        background-color: #F5F2F2;
        border: 1px #737373 solid;
    }

    #enviarBtn:disabled {
        opacity: 0.5;
    }
</style>